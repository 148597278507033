.single-team h4{
    padding-top: 3rem;
    padding-bottom: 1rem;
    color: var(--black);
    font-size: 3rem;
}

.single-team .team-intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 3rem;
}

.single-team .team-intro .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
    height: 40rem;
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    overflow: hidden;
}

.single-team .team-intro .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.single-team .team-intro .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70rem;
        flex: 1 1 70rem;
}

.single-team .team-intro .intro h3{
    color: var(--black);
    font-size: 4rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.single-team .team-intro .intro p{
    color: var(--secondary-color);
    font-size: 2.5rem;
    font-weight: bold;
}

.single-team .contact-details{
    padding-top: 2rem;
}

.single-team .contact-details .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

.single-team .contact-details .item{
   -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
        flex: 1 1 33rem;
}

.single-team .contact-details .item p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

.single-team .contact-details .item p .icon{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;  
    font-size: 2rem;
    color: var(--secondary-color);
    height: 2rem;
    width: 2rem;
}

.single-team .contact-details .item p span{
    color: var(--grey);
    font-size: 1.6rem;
    text-transform: none;
}

.single-team .team-about p{
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
}

.single-team .team-skills ul{
    list-style-type: none;
}

.single-team .team-skills li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;
}

.single-team .team-skills li .icon{
    font-size: 1rem;
    color: var(--secondary-color);
}

.single-team .team-skills li span{
    font-size: 1.6rem;
    color: var(--grey);
}
