.blog-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white);
}

.blog-item .image{
  height: 25rem;
}  

.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
} 

.blog-item .content{
  padding: 1.5rem;
}

.blog-item .details{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2rem;
  padding-bottom: 1rem;
}

.blog-item h3{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.6rem;
  color: var(--grey);
}

.blog-item h3 .icon{
  color: var(--secondary-color);
}

.blog-item h3 span{
  font-weight: 400;
}

.blog-item .content .main-heading{
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
  line-height: 1.4;
  padding-bottom: 0.5rem;
}

.blog-item .content .main-heading:hover{
  color: var(--main-color);
}

.blog-item .content p{
  font-size: 1.6rem;
  color: var(--grey);
  line-height: 1.5;
}
