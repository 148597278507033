.order-list-container{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    overflow-x:auto;
    overflow-y:hidden; 
}

.order-list .container{ 
    min-width: 90rem; 
} 

.order-list .title{
    background-color: var(--secondary-color);
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    margin-bottom: 2rem;
}

.order-list .title h3{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    font-size: 1.8rem;
    text-align: center;
}

.order-list .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem 1rem;
}

.order-list .item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    color: var(--black);
}

.order-list .item .order-id{
    font-weight: 500;
}

.order-list .item .status.cancelled{
    color: red;
}

.order-list .item .status.processing{
    color: var(--grey);
}

.order-list .item .status.completed{
    color: green;
}

.order-list .item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.order-list .item .icon{
    font-size: 2rem;
    cursor: pointer;
    margin: 0rem 0.5rem;
    color: var(--secondary-color);
}

.order-list .item .icon:hover{
    color: var(--black);
}

.order-list .shoplist-title h3:first-child{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 10rem;
        flex: 1 1 10rem;
  }
