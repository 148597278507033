.address-details .address{
    -webkit-box-flex: 1;
    -ms-flex:1 1 22rem; 
        flex:1 1 22rem; 
}  
 
.address .title{
    background-color: var(--secondary-color);
    color: var(--white);
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
}

.address .title h3{ 
    font-size: 1.8rem;
}

.address .info p{ 
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--grey);
    padding-bottom: 1rem;
}

.address .info p span{
    font-weight: bold;
    color: var(--black);
    padding-right: 1rem;
}