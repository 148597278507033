
.shop-sidebar .product-item .options{
    display: none;
}

.shop-sidebar .product-item{
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-bottom: 1rem;
    text-align: left;
}

.shop-sidebar .product-item .content{
    padding: 0.5rem;
    text-align: left;
}

.shop-sidebar .product-item .image img{
    height: 8rem;
    width: 8rem;
}

.shop-sidebar .product-item h3{
    font-size: 2rem;
}

.shop-sidebar .product-item .rating{
    font-size: 1.7rem;
}

.shop-sidebar .product-item .price{
    font-size: 1.7rem;
}

.shop-sidebar .product-item .price span{
    font-size: 1.3rem;
}
