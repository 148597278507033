.heading{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.heading h2{
  font-size: 4rem;
  font-weight: 600;
}

.heading.transparent-bg h2{
  color: var(--white);
}

.heading .design{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
}

.heading .design span{
  display: inline-block;    
  background: var(--main-color);
  width: 60%;
  height: 0.15rem;
}

.heading.transparent-bg .design span{
  background: var(--white);
}  
 
.heading .design .icon{
  font-size: 3rem;
  color: var(--main-color);
}

.linear-bg .heading h2{
  color: var(--white);
}

.linear-bg .heading .design span{
  background: var(--white);
}

.linear-bg .heading .design .icon{
  color: var(--white);
}
