.single-event{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 3rem;
}

.event-container{
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70rem;
        flex: 1 1 70rem;
}

.event-info{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow); 
}

.event-info .image{
    height: 40rem;
}

.event-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/*----- Event Intro -----*/
.event-info .date{
    position: absolute;
    top: 0;
    left: 0;
    width: 7rem;
    text-align: center;
    background-color: var(--secondary-color);
    color: var(--white);
    padding: 1rem;
    font-size: 2.5rem;
    opacity: 0.8;
}

.event-info .content{
    padding: 1.5rem;
}

.event-info .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.event-info .details h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.4rem;
    color: var(--grey);
}

.event-info .details h3 .icon{
    color: var(--secondary-color);
}

.event-info .details h3 span{
    font-weight: 400;
}

.event-info .content .main-heading{
    display: block;
    font-size: 4rem;
    font-weight: bold;
    color: var(--black);
    line-height: 1.4;
    padding-bottom: 1.5rem;
}

.event-info .content .text{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.5;
    padding-bottom: 1.5rem;
}

.event-info h6{
    font-size: 2.5rem;
    color: var(--black);
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}

/*----- Event Content -----*/
.event-info .event-content ul{
    list-style: none;
}

.event-info .event-content ul li{
    padding: 0.9rem 0;
    font-size: 1.5rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 1rem;
}

.event-info .event-content ul li .icon{
    color: var(--secondary-color);
    font-size: 1rem;
}

/*----- Event Speakers -----*/
.event-info .event-speakers .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(20rem, 1fr))[auto-fit];
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1rem;
    padding: 1rem 0;
}

/*----- Event Information -----*/
.event-info .event-information .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    padding: 2rem;
    background-color: rgba(28, 103, 88, 0.1);
    border-radius: var(--border-radius);
    margin-bottom: 1rem;
}

.event-info .event-information ul{
    list-style-type: none;
}

.event-info .event-information ul li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;
}

.event-info .event-information ul li .icon{
    height: 2rem;
    width: 2rem;
    font-size: 1.5rem;
    color: var(--secondary-color);
}

.event-info .event-information ul li h4{
    width: 12rem;
    font-size: 2rem;
    color: var(--black);
}

.event-info .event-information ul li span{
    font-size: 1.6rem;
    color: var(--grey);
}

.event-info .event-information ul li .gmail{
    text-transform: none;
}
