.controls .button{
    padding: 0.7rem 1rem;
    font-size: 2rem;
    color: var(--grey);
    font-weight: 600;
}

.controls .button:hover,
.controls .button.active{
    cursor: pointer;
    color: var(--main-color);
}

._3bkri {
    background-color: var(--main-color) !important;
}

._20cg_ {
	background-color: var(--main-color) !important;
}

._20cg_:hover {
	background-color: var(--main-color) !important;
}

._20cg_:focus {
	-webkit-box-shadow: 0px 0px 0px 3px var(--secondary-color) !important;
			box-shadow: 0px 0px 0px 3px var(--secondary-color) !important;
}
