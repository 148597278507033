.blog-details{
    display: -webkit-box;  
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 3rem;
    -ms-flex-wrap: wrap; 
        flex-wrap: wrap;  
}

.blog-details .blog-container{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70rem;
        flex: 1 1 70rem;
}

/*----- 1- Blog Info -----*/
.blog-info{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
  } 
    
.blog-info .image{ 
    height: 40rem; 
}
  
.blog-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog-info .content{
    padding: 2rem;   
}

.blog-info .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
}

.blog-info .details h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.6rem;
    color: var(--grey);
}

.blog-info .details h3 .icon{
    color: var(--secondary-color);
}

.blog-info .details h3 span{
    font-weight: 400;
}
  
.blog-info .content .main-heading{
    display: inline-block;
    font-size: 3.5rem;
    font-weight: 500;
    color: var(--black);
    padding-bottom: 1.5rem;
}
  
.blog-info p{
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}
  
.blog-info .important{
    padding: 1rem 4rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
    font-style: italic;
    line-height: 1.8;
    border-left: 0.2rem solid var(--main-color);
    margin-bottom: 2rem;
    margin-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 3rem;
    background-color: rgba(84, 180, 53, 0.3);
}

.blog-info .important .icon{
    font-size: 15rem;
    color: var(--main-color);
}

.blog-info .blog-points h4{
    font-size: 2.5rem;
    color: var(--black);
    padding-bottom: 1.5rem;
}

.blog-info .blog-points ul{
    list-style-type: none;
}

.blog-info .blog-points ul li{
    font-size: 1.4rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.blog-info .blog-points ul li .icon{
    font-size: 1.4rem;
    color: var(--main-color);
}

.blog-info .blog-gallery{ 
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1rem;
    margin: 2rem 0;
}

.blog-info .blog-gallery img{
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.blog-info .end-details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

.blog-info .tags, 
.blog-info .share{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.blog-info .share h3{
    color: var(--black);
    font-size: 1.4rem;
    padding-right: 1rem;
}

.blog-info .tags span{
    color: var(--grey);
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.blog-info .share .icon{ 
    color: var(--grey);
    font-size: 1.6rem;
}

.blog-info .share .icon:hover{
    color: var(--secondary-color);
    cursor: pointer;
}

/*----- 2- Leave Reply -----*/
.leave-reply{
    width: 100%;
} 
    
  