.prayer-timing{
    width: 100%;
    padding: 4rem 5%;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Namaz.png");
    background:         linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Namaz.png");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.prayer-timing .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 2rem;
    margin-top: 10rem;
    margin-bottom: 4rem;
}
  
.prayer-timing .prayer-item{
    min-height: 15rem;
    background-color: rgba(255, 255, 255, 0.2);
}  

.prayer-timing .prayer-item h4{
    background-color: var(--main-color);
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    padding: 2rem;
    text-align: center;
}

.prayer-timing .prayer-item .content{
    list-style-type: none;
    padding: 3rem 2rem;
}

.prayer-timing .prayer-item .content li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 2rem;
}

.prayer-timing .prayer-item .content li:last-child{
    padding-bottom: 0;
}

.prayer-timing .prayer-item .content h6{
    font-size: 2.2rem;
    color: var(--main-color);
}

.prayer-timing .prayer-item .content span{
    font-size: 1.8rem;
    color: var(--white);
}
