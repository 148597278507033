.checkout{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    gap: 2rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}   

.checkout .heading .design{
    display: none;
}

.checkout .heading{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.checkout-item{  
    background-color: var(--white);
    padding: 1rem;
    margin-bottom: 2rem;
}

.checkout .box-1{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 60rem;
        flex: 1 1 60rem;
    width: 100%;
    padding: 1rem 0;
}

.checkout .box-2{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
}

/*----- 1- Billing Address -----*/
/*----- 2- Shipping Address -----*/
.checkout .form{
    box-shadow: none;
    border: none;
    padding: 0;
}

/*----- 3- Payment Method -----*/
.payment-methods .item{
    width: 100%;
    margin-bottom: 1.5rem;
}

.payment-methods .item label{
    cursor: pointer;
    font-size: 2rem;
    color: var(--black);
}

.payment-methods .item  input{
    margin-right: 0.5rem;
}

.payment-methods .item input:checked + label, 
.payment-methods .item label:hover { 
    color: var(--main-color);
}

.payment-methods .item .payment-body { 
    display: none;
}

.payment-methods .item .payment-body p{
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
}

.payment-methods .item > input:checked ~ .payment-body { 
    display: block;
}

.payment-body.active{
    display: block; 
}

.checkout .btn{
    width: 100%;
    text-align: center;
}