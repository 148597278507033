.cause-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white);
}

.cause-item .image{ 
  height: 25rem;
}

.cause-item .image img{ 
  height: 100%;
  width: 100%;
  object-fit: cover;
} 

.cause-item .content{
  padding: 2rem;
}

.cause-item .content .main-heading{
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--black);
  line-height: 1.4;
  padding-bottom: 0.5rem;
}

.cause-item .content .main-heading:hover{
  color: var(--main-color);
}

.cause-item .content .date{
  text-align: center;
  display: inline-block;
  font-size: 1.4rem;
  color: var(--grey);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}

.cause-item .content .date .icon{
  color: var(--secondary-color);
}

.cause-item .content p{
  font-size: 1.6rem;
  color: var(--grey);
  line-height: 1.5;
  padding: 1rem 0;
}

.cause-item .skill-bar{
  background-color: rgba(0, 0, 0, 0.1);
  height: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 3rem;
}

.cause-item .skill-progress{
  position: relative;
  display: block;
  background-color: var(--secondary-color);
  height: 1rem;
  border-radius: 0.5rem;
}

.cause-item .skill-no{
  position: absolute;
  right: -1.4rem;
  top: -2.8rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  background-color: var(--secondary-color);
  padding: 0.2rem 0.6rem;
  border-radius: 0.3rem;
  z-index: 1;
}

.cause-item .skill-no::before{
  content: '';
  position: absolute;
  left: 50%;
  bottom: -0.2rem;
  height: 1rem;
  width: 1rem;
  z-index: -1;
  background-color: var(--secondary-color);
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}

.cause-item .amount{ 
  padding-top: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify; 
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cause-item .amount p{
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--grey);
}

.cause-item .amount p span{
  color: var(--secondary-color);
}