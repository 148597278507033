.single-service{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
        align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 2rem;
} 

.service-sidebar{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem;
        flex: 1 1 25rem;
}

.service-sidebar h3{
    font-size: 2rem;
    font-weight: 400;
    color: var(--grey); 
    padding: 1.5rem 0;
    gap: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.service-sidebar h3:hover{
    cursor: pointer;
    color: var(--main-color);
}

.service-sidebar h3 .icon{
    color: var(--main-color);
    font-size: 2rem;
}

.service-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70rem;
        flex: 1 1 70rem;
}

/*----- Image -----*/
.service-info .image{
    height: 40rem; 
}

.service-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/*----- Content -----*/
.service-info .content{
    padding-top: 2rem;
}

.service-info .main-heading{
    display: inline-block;
    font-size: 4rem;
    font-weight: bold;
    color: var(--secondary-color);
    padding-bottom: 1rem;
}

.service-info p{
    padding-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}

/*----- Service Gallery -----*/
.service-info .service-gallery{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1rem;
    padding-bottom: 1rem;
}

.service-info .service-gallery img{
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

/*----- Service Points -----*/
.service-info .sub-heading{
    display: inline-block;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--black);
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}

.service-info .service-features{
    list-style: none;
}

.service-info .service-features li{
    padding: 0.9rem 0;
    font-size: 1.7rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 0.5rem;
}

.service-info .service-features li .icon{ 
    color: var(--main-color);
    font-size: 2rem;
}

/*----- Service Faqs -----*/
.service-faqs{
    width: 100%;
}

.service-faqs .accordion{
    background: var(--white);
    margin-bottom: 1.5rem;
    -webkit-box-shadow: var(--box-shadow); 
            box-shadow: var(--box-shadow);
    overflow: hidden;
    border-bottom: 0.2rem solid var(--secondary-color);
} 

.service-faqs .accordion.active{
    border-color: var(--main-color);
}
 
.service-faqs .accordion:last-child{
    margin-bottom: 0rem;
}  

.service-faqs .accordion .accordion-heading{
    padding: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 1rem;
    background: var(--secondary-color); 
    cursor: pointer;
}

.service-faqs .accordion.active .accordion-heading{
    background-color: var(--main-color);
}

.service-faqs .accordion .accordion-heading h3{
    font-size: 2rem;
    color: var(--white);
}

.service-faqs .accordion .accordion-heading .icon{
    font-size: 2rem;
    color: var(--white);
}

.service-faqs .accordion.active .accordion-content{
    display: block;
}

.service-faqs .accordion-content{
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
    display: none;
}


