.header .nav-item{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .dropdown-menu{
    display: inline-block;
    font-size: 2rem;
    color: #222;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    gap: 1rem;
}

.header .dropdown-menu .nav-btn{
    display: inline-block;
    font-size: 2rem;
    color: #222;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    gap: 1rem;
}

.header .dropdown-menu .icon{
    font-size: 2rem;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .dropdown-content{
    position: absolute;
    top: 100%;
    left: 0;
    color: var(--black);
    background-color: var(--white);
    padding: 1rem;
    width: 15rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: none;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition:  all 0.5s;
    -moz-transition:  all 0.5s;
    -o-transition:  all 0.5s;
    transition:  all 0.5s;
    z-index: 1000;
}

.header .dropdown-content a{
    display: block;
    font-size: 1.8rem;
    color: var(--black);
    padding: 1rem 0;
    cursor: pointer;
}

.header .dropdown-content a:last-child{
    padding-bottom: 0rem;
}

.header .dropdown-content a:hover{
    color: var(--secondary-color);
}

.header .nav-item:hover .nav-btn{
    color: var(--main-color);
}

.header .nav-item:hover .icon{
    color: var(--main-color);
}

.header .nav-item:hover .dropdown-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale(1);
            transform: scale(1);
}



