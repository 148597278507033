.logo{ 
    display: -webkit-box;  
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;  
    gap: 1rem;
}

.logo img{
    height: 4rem;
    object-fit: cover;
}

.logo h3{ 
    color:var(--black);
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

.logo p{ 
    color:var(--secondary-color);
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}
