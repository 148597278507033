.testimonials{
    overflow: hidden;
}  

.testimonial-slider{ 
    overflow: hidden;
    position: relative;
}

.testimonial-slider .swiper{
    overflow: visible !important;
    margin: 0rem 15rem;
    padding: 2rem 0;
    padding-bottom: 5rem;  
}

.testimonial-slider .swiper-pagination-bullet{ 
    background: rgba(0, 0, 0, 0.2);
}

.testi-item{
    padding: 5rem 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;  
    text-align: center;
    border: 0.2rem solid var(--main-color);
    position: relative;
    border-bottom-right-radius: 10rem;
    border-top-left-radius: 10rem;
}

.testi-item .top-quote{
    color: var(--main-color);
    font-size: 5rem;
}

.testi-item p{
    font-size: 1.8rem;
    line-height: 2;
    padding: 2rem 0;
    color: var(--grey);
}

.testi-item p .icon{
    font-size: 1.8rem;
    color: var(--secondary-color);
}

.testi-item h4{
    font-size: 2rem;
    color: var(--secondary-color);
}


