.wishlist-container{
    overflow-x:auto;
    overflow-y:hidden; 
}

.wishlist .container{
    min-width: 90rem;
}

.wishlist .box-container{ 
    padding: 1rem;
}

.wishlist-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.wishlist-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem; 
    text-align: center; 
    font-size: 2rem;
    font-weight: 400;
    color: var(--black);
}

.wishlist-item .product{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 2rem;
}

.wishlist-item img{
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    background-color:#f7f7f7;
}

.wishlist-item .name{
    color: var(--secondary-color);
    font-weight: 600;
}

.wishlist-item .price{
    color: var(--grey);
}

.wishlist-item .status.InStock{
    color: var(--main-color);
}

.wishlist-item .status.OutOfStock{
    color: red;
}

.wishlist-item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.wishlist-item .icon{
    font-size: 2rem;
    cursor: pointer;
}

.wishlist-item .icon:hover{
    color: var(--black);
}

.wishlist-item .delete{
    color: red;
}

.wishlist-item .cart{
    color: var(--main-color);
}
