.page-title{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("../../assets/PageTitle/PageTitle.png");
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("../../assets/PageTitle/PageTitle.png");
    min-height: 35rem;
    background-repeat: no-repeat;  
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.page-title .title h2{
    color: var(--white);
    font-size: 5rem;
    font-weight: 500;
    padding-top: 4rem;
    text-transform: uppercase;
    padding-bottom: 2rem;
}

.page-title .link{
    min-width: 15rem;
    padding: 1rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
}

.page-title .link a{
    font-size: 2.5rem;
    color: var(--white);
}

.page-title .link a:hover{
    color: var(--main-color);
}

.page-title .link .icon{
    font-size: 1.6rem;
    color: var(--white);
}

.page-title .link span{
    font-size: 2.2rem;
    color: var(--main-color);
}
