.pillars{
    width: 100%;
    padding: 4rem 5%;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Pillars.png");
    background:         linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Pillars.png");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.pillars .box-container{ 
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 3rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
}

.pillar-item{
    text-align: center;
}

.pillar-item .icon{
    height: 15rem;
    width: 15rem;
    font-size: 4rem;
    color: var(--secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    background-color: var(--white);
    border-radius: 50%;
    outline: 0.15rem solid var(--white);
    outline-offset: 1rem;
    margin-bottom: 3rem;
}

.pillar-item h4{
    font-size: 2.5rem;
    color: var(--white);
    padding-bottom: 1rem;
}

.pillar-item p{
    font-size: 1.4rem;
    color: var(--white);
    line-height: 1.5;
}
