.shop .product-container.list .product-item{
    width: 100%;
    margin-bottom: 1rem;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem; 
} 
 
.shop .product-container.list .product-item .image{
    min-height: 25rem; 
    min-width: 25rem;
}

.shop .product-container.list .product-item .image img{
    height: 100%;
    width: 25rem;
    object-fit: cover;
}

.shop .product-container.list .product-item .content{
    padding: 1.5rem;
    text-align: left;
}

.shop .product-container.list .product-item .content > p{
    font-size: 1.6rem;
    padding: 1rem 0;
    line-height: 1.5;
}