.profile .heading{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.profile .heading .design{
    display: none;
}

/*----- Profile -----*/
.profile-item{ 
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow); 
    padding: 2rem;
    margin-bottom: 1rem;
} 

.profile .box-container{ 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
        align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.profile .profile-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
    width: 100%;
}
 
.profile .profile-details .content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.profile .profile-details .image{
    text-align: center;
}

.profile .profile-details .image img{
    height: 20rem;
    width: 20rem;
    border: 0.1rem solid var(--black);
    border-radius: 50%;
    margin-bottom: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.profile .profile-details .content h3{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--black);
}

.profile .profile-details .info .container{
    margin: 2rem auto;
    width: 80%;
    text-align: left;
}

.profile .profile-details .info .box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
}

.profile .profile-details .info .box .icon{
    height: 2rem;
    width: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    color: var(--main-color);
}

.profile .profile-details .info .box span{
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--grey);
}

.profile .profile-details .content .gmail{
    text-transform: none;
}

/*----- Address -----*/
.profile .address-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 60rem; 
        flex: 1 1 60rem;
}

.profile .address-details .address-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1.5rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.profile .btn{
    margin-top: 1rem;
}
