.btn {
    display: inline-block;
    color: var(--white);
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1.5rem 3rem;
    position: relative;
    overflow: hidden;
    background: var(--secondary-color);
    background: var(--secondary-color);
    border-radius: 1rem;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background-color: var(--main-color);
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
}

.btn:hover {
    /*background: -webkit-linear-gradient(left, var(--main-color), var(--secondary-color));
    background:         linear-gradient(90deg, var(--main-color), var(--secondary-color));*/
    cursor: pointer;
}
