.blog-details .comments{
    background-color: var(--white);
    padding: 1rem;
    margin: 2rem 0;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
} 
 
.blog-details .comments h4{  
    font-size: 2rem; 
    color: var(--black); 
    padding-bottom: 2rem;
} 

.blog-details .comments .replies{
    margin-left: 10rem;
}