.slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /*width: 80%;*/
    margin: auto;
    overflow: hidden;
    margin-top: 20px !important;
}

.slider-image {
    width: 100%;
    /*max-height: 600px;*/
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
}

.slider-button:hover {
    background: rgba(0, 0, 0, 0.8);
}

.slider-button:first-of-type {
    left: 10px;
}

.slider-button:last-of-type {
    right: 10px;
}
