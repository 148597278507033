.comment{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    padding: 1rem 0;
}

.comment .image{
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    overflow: hidden;
}

.comments .image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.comments .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.comments .content .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: justify; 
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 0.5rem;
}

.comment .content .intro h3{
    font-size: 1.8rem;
    color: var(--black);
}

.comment .content .intro h6{
    font-size: 1.5rem;
    color: var(--grey);
    padding: 0.5rem 0;
}

.comment .content .text{
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}

.comment .icon-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.3rem;
}

.comment .icon-item .icon{ 
    color: var(--secondary-color);
    font-size: 1.5rem;
}

.comment .icon-item span{
    color: var(--secondary-color);
    font-size: 1.6rem;
}

.comment .icon-item:hover .icon,
.comment .icon-item:hover span{
    cursor: pointer;
    color: var(--main-color);
}


