.blog.grid{
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 3rem;
}  
  
.blog-container{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70rem;
        flex: 1 1 70rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.blog-container.grid .blog-items{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1rem;
} 

