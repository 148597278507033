.cart .shopping-cart{
    background-color: var(--white);
    margin-bottom: 1rem;
    overflow-x:auto;
    overflow-y:hidden;  
}  
 
.cart .shopping-cart .container{
    min-width: 90rem; 
}
 
.cart .box-container{
    padding: 1rem;
}

.cart-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
    gap: 3rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
} 

.cart-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 15rem;
        flex: 1 1 15rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    color: var(--black);
}

.cart-item .product{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 3rem;
}

.cart-item img{
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    background-color:#f7f7f7;
}

.cart-item .name{
    color: var(--secondary-color);
    font-weight: 500;
}

.cart-item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cart-item .icon{
    font-size: 2rem;
    cursor: pointer;
    color: red;
}

.cart-item .icon:hover{
    color: var(--secondary-color);
}

.cart-item .delete{
    color: red;
}

.cart .cart-total{
    width: 40rem;
    background-color: var(--white);
    margin-left: auto;
    padding: 0rem 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-top: 0.3rem solid var(--secondary-color);
}

.cart .cart-total .btn{
    width: 100%;
    text-align: center;
    margin: 1rem 0;
}
