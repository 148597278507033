.blog-sidebar .blog-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: none; 
            box-shadow: none;
    margin-bottom: 1rem;
    gap: 1rem;
}

.blog-sidebar .blog-item img{
    height: 8rem;
    width: 12rem;
    border-radius: var(--border-radius);
}

.blog-sidebar .blog-item .content{
    padding: 0rem;
}

.blog-sidebar .blog-item .content .main-heading{
    font-size: 1.8rem;
    font-weight: 500;
}

