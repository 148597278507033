.services .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1rem;
}

.service-item{
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border: var(--border);
    border-bottom: 0.5rem solid var(--main-color);
}

.service-item .image{
    height: 25rem;
}

.service-item img{
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.service-item .content{
    padding: 2rem;
    background-color: var(--white);
}

.service-item .main-heading{
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 1rem;
}

.service-item .main-heading:hover{
    color: var(--main-color);
}

.service-item p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.7;
}
