.sidebar-heading{
    margin-bottom: 2rem;
}

.sidebar-heading h2{
    word-spacing: 1px; 
    letter-spacing:2px; 
    font-size: 3rem; 
    font-weight: 400;
    color: var(--black);
    position: relative;
    padding-bottom: 1rem;
} 

.sidebar-heading h2:after, 
.sidebar-heading h2:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45px;
    height: 4px;
    content: "";
    margin: auto;
    background-color: var(--main-color);
}
  
.sidebar-heading h2:before { 
    background-color: #ccc;
    left: 0; 
    width: 90px;
}