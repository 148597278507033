.product-item{
    position: relative;
    overflow: hidden;
    -webkit-transition: all 1s ease;  
    -moz-transition: all 1s ease; 
    -o-transition: all 1s ease;  
    transition: all 1s ease; 
} 
  
.product-item .options{
    position: absolute;
    top: -100%;
    left: 0; 
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    gap: 1rem;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: var(--border-radius);
}

.product-item:hover .options{
    top: 0;
}

.product-item .options a{
    height: 4rem;
    width: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--white);
}

.product-item .options a:hover{
    background-color: var(--secondary-color);
}

.product-item .options a .icon{ 
    font-size: 2rem;
    color: var(--secondary-color);
    cursor: pointer;
}

.product-item .options a:hover .icon{
    color: var(--white);
}

.product-item .image{
    position: relative;
    overflow: hidden;
}

.product-item .image img{
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.product-item .content{
    padding: 2rem;
    text-align: center;
}

.product-item h3{
    font-size: 2rem;
    font-weight: 500;
    color: var(--black);
    padding-bottom: 0.5rem;
}

.product-item h3:hover{
    color: var(--main-color);
}

.product-item .price{
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--main-color);
}

.product-item .price span{ 
    font-size: 1.4rem;
    font-weight: 400;
    text-decoration: line-through;
    color: var(--grey);
    padding-left: 0.2rem;
}

.product-item:hover .options{
    bottom: 0%;
}
