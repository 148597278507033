.event-sidebar .event-item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  box-shadow: none;
}
  
.event-sidebar .event-item img{
  height: 8rem;
  width: 12rem;
  border-radius: var(--border-radius);
}

.event-sidebar .event-item .content{
  padding: 0rem;
}
  
.event-sidebar .event-item .main-heading{
  font-size: 1.8rem;
}
  

