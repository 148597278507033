.team-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
}

.team-item .image{
  height: 40rem;
}

.team-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.team-item .content{
  padding: 1.5rem;
  text-align: center;
  position: absolute;
  background-color: var(--main-color);
  left: 1rem;
  bottom: 1rem;
  right: 1rem;
  border-radius: 0.5rem;
}

.team-item .content a{
  display: inline-block;
  color: var(--white);
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.team-item .content h5{
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
}

.team-item .line{
  height: 0.2rem;
  width: 50%;
  background-color: var(--white);
  margin: 1rem auto;
}

.team-item .icon-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center; 
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  gap: 0.5rem;
  margin-top: 1rem;
}

.team-item .icon-container a{
  height: 4rem;
  width: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  font-size: 2rem;
  color: var(--white);
}
