/*--------------------- 0- Global CSS (Start) ---------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
    --main-color: #54B435;
    --secondary-color: #1C6758;
    --black: #000;
    --white: #fff;
    --grey: #666;
    --border-radius: 1rem;
    --border: 0.1rem solid rgba(0, 0, 0, 0.1);
    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    --text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

*{
    font-family: 'Roboto', sans-serif;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: none;
    border: none;
    outline: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

*::selection{
    color: var(--white);
    background-color: var(--main-color);
}

body{
    background-color: var(--white);
    overflow-x: hidden;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

section{
    padding: 2rem 5%;
}

::-webkit-scrollbar{
    width: 0.8rem;
}

::-webkit-scrollbar-track{
    background: var(--secondary-color);
}

::-webkit-scrollbar-thumb{
    background: var(--main-color);
}


/*----- 2- Form CSS -----*/
.form{
    padding: 5rem;
    background: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    margin: 1rem auto;
    text-align: center;
    border: var(--border);
}

.form.account{
    max-width: 45rem;
}

.form.edit{
    max-width: 80rem;
}

.form h3{
    font-size: 3rem;
    font-weight: 500;
    padding-bottom: 1rem;
    color: #333;
    text-transform: uppercase;
    text-align: center;
}

.form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.form .input-box .box{
    width: 49%;
}

.form .box{
    width: 100%;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    font-size: 1.6rem;
    color: var(--grey);
    text-transform: none;
    margin: 0.7rem 0;
}

.form .box::placeholder{
    text-transform: capitalize;
}

.form .box:focus{
    border-color: var(--secondary-color);
}

.form p{
    padding-top: 2rem;
    font-size: 1.5rem;
    color: var(--grey);
    text-align: center;
    padding-bottom: 1rem;
}

.form .link{
    font-size: 1.5rem;
    color: var(--secondary-color);
}

.form .link:hover{
    text-decoration: underline;
}

.form button{
    width: 100%;
    margin-top: 1rem;
}

.form .btn{
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
}

.form textarea{
    resize: none;
    height: 20rem;
}

/*----- 3- Sidebar CSS -----*/
/*
1- Search
2- Category
3- Recent Items
4- Tags
*/

.sidebar{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem;
        flex: 1 1 25rem;
}

.sidebar .sidebar-item{
    margin-bottom: 2.5rem;
}

.sidebar .box-container{
    padding: 1rem 0;
    gap: 1rem;
}

/*-- 1- Search --*/
.sidebar .search .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2rem;
    overflow: hidden;
}

.sidebar .search .input-box input{
    width: 100%;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.sidebar .search .input-box input:focus{
    border-color: var(--main-color);
}

.sidebar .search .input-box label{
    color: var(--white);
    background-color: var(--secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
}

.sidebar .search .input-box label:hover{
    background-color: var(--main-color);
}

/*-- 2- Category --*/
.sidebar .category .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.2rem 0;
    border-bottom: 0.15rem solid rgba(0, 0, 0, 0.1);
}

.sidebar .category .item a{
    font-size: 2rem;
    color: var(--grey);
    font-weight: 400;
    cursor: pointer;
}

.sidebar .category .item a:hover{
    color: var(--secondary-color);
}

.sidebar .category .item p{
    font-size: 1.8rem;
    color: var(--grey);
}

/*-- 3- Recent Post --*/
.sidebar .recent-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

/*-- 4- Tags --*/
.sidebar .tags .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.sidebar .tag-item{
    color: var(--grey);
    border: 0.15rem solid rgba(0, 0, 0, 0.1);
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
}

.sidebar .tag-item:hover{
    background-color: var(--secondary-color);
    color: var(--white);
    cursor: pointer;
}


/*----- 4- Swiper Pagination CSS -----*/
.swiper-pagination-bullet{
    height: 1rem;
    width: 4rem;
    border-radius: 0rem;
    background: var(--white);
    opacity: 0.7;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.swiper-pagination-bullet-active{
    background: var(--main-color) !important;
    opacity: 0.9 !important;
}

.see-more-btn{
    margin-top: 1rem;
}

.row{
    display: flex;
    flex-direction: row;

}
.space-between{
    justify-content: space-between ;
}

/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
    html{
      font-size: 55%;
    }

    .blog.grid, .blog.list{
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
    }

    .testimonial-slider .swiper{
        margin: 0rem 7rem;
    }

  }

  @media (max-width: 768px){

    section, .header .header-1, .header .header-2{
        padding: 2rem;
    }

    .footer{
        padding: 0rem 2rem;
    }

    .home-item .content{
        padding: 2rem 10%;
    }

    .testimonial-slider .swiper{
        margin: 0rem 5rem;
    }

    .shop{
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
    }

  }

  @media (max-width: 450px){

    html{
        font-size: 50%;
    }

    section, .header .header-1, .header .header-2{
        padding: 1rem;
    }

    .footer{
        padding: 0rem 1rem;
    }

    .home-item .content{
      padding: 2rem 14%;
    }

    .contact-form .input-box{
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    .form .input-box .box{
      width: 100%;
    }

    .cart .cart-total{
      width: 100%;
    }

    .testimonial-slider .swiper{
        margin: 0rem;
    }

  }

  /*--------------------- Responsive (End) ---------------------*/
