.single-order{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.single-order .heading{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.single-order .heading .design{
    display: none;
}

.single-order .single-order-item{
    width: 100%;
    background-color: var(--white);
    padding: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border: var(--border);
}

.single-order-intro{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;  
}

/*----- 1- Order Summary -----*/
.single-order .order-summary{
    width: 100%;
}

.single-order .order-summary .order-intro{
    border-bottom: 0.2rem solid var(--grey);
}

.single-order .order-intro .order-id{
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
    padding-bottom: 1rem;
}

.single-order .order-intro .delivery{
    color: var(--grey);
    font-size: 1.8rem;
    padding-bottom: 1rem;
}

/*----- 2- Address Details -----*/
.address-details .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1.5rem;
}

/*----- 3-Order Items -----*/
.order-items{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 65rem; 
        flex: 1 1 65rem; 
    margin-bottom: 0rem;
    overflow-x: auto;
    overflow-y: hidden; 
}

.order-items .container{
    min-width: 60rem;
}

.order-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.order-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 10rem;
        flex: 1 1 10rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 400; 
    color: var(--black);
}

.order-item .product{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 1.5rem;
}

.order-item img{
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    background-color:#f7f7f7;
}

.order-item .name{
    color: var(--secondary-color);
    font-weight: 500;
}

.order-item .price{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 7rem;
        flex: 1 1 7rem;
}