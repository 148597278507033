.blog.list{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    gap: 2rem; 
}  

.blog-container.list .blog-item{
    width: 100%;
    margin-bottom: 1rem;
}

.blog-container.list .blog-item .image{
    height: 30rem;
}
