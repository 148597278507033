.gallery-item{
    overflow: hidden;
    position: relative;
    height: 25rem;
}

.gallery-item.double{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
}

.gallery-item.single{
   -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem; 
}

.gallery-item img{
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.gallery-item .content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    position: absolute;
    left: 10%;
    top: 10%;
    opacity: 0;
    width: 80%;
    height: 80%;
    z-index: 1;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    background-color: rgba(28, 103, 88, 0.8);
}

.gallery-item:hover .content{
    opacity: 1;
}

.gallery-item a .icon{
    scale: 0;
    font-size: 4rem;
    color: var(--white);
    cursor: pointer;
}

.gallery-item:hover a .icon{
    scale: 1;
}
