.cause-sidebar .cause-item p,
.cause-sidebar .cause-item .skill-bar,
.cause-sidebar .cause-item .amount{
    display: none;
}

.cause-sidebar .cause-item{
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-bottom: 1rem;
  }
  
  .cause-sidebar .cause-item .image{ 
    height: 8rem;
    width: 12rem;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
  
  .cause-sidebar .cause-item .content{
    padding: 0.5rem;
  }
  
  .cause-sidebar .cause-item .content .main-heading{
    font-size: 2rem;
  }
  
