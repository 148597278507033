.single-cause{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 3rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.cause-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70rem;
        flex: 1 1 70rem;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.cause-info .image{
    height: 40rem;
}

.cause-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/*----- Cause Intro -----*/
.cause-info .intro{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    width: 80%;
    margin:  0 auto;
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    margin-bottom: -5rem;
    padding: 2rem;
    border-radius: 1rem;
}

.cause-info .intro .main-heading{
    display: block;
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--black);
    line-height: 1.4;
    padding-bottom: 0.5rem;
}

.cause-info .intro .date{
    display: inline-block;
    font-size: 1.4rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
}

.cause-info .intro .date .icon{
    color: var(--secondary-color);
}

.cause-info .skill-bar{
    background-color: rgba(0, 0, 0, 0.1);
    height: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    margin-top: 3rem;
}

.cause-info .skill-progress{
    position: relative;
    display: block;
    background-color: var(--secondary-color);
    height: 1rem;
    border-radius: 0.5rem;
    width: 50%;
}

.cause-info .skill-no{
    position: absolute;
    right: -1.4rem;
    top: -2.8rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--white);
    background-color: var(--secondary-color);
    padding: 0.2rem 0.6rem;
    border-radius: 0.3rem;
    z-index: 1;
}

.cause-info .skill-no::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.2rem;
    height: 1rem;
    width: 1rem;
    z-index: -1;
    background-color: var(--secondary-color);
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
}

.cause-info .intro .amount{
    padding-top: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify; 
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cause-info .intro .amount p{
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--grey);
}

.cause-info .intro .amount p span{
    color: var(--secondary-color);
}

.cause-info .content{
    padding: 1rem;
    text-align: left;
    box-shadow: none;
    border: none;
}

.cause-info .content p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.5;
    padding: 1rem 0;
    text-align: left;
}

.cause-info h4{
    font-size: 2.5rem;
    color: var(--black);
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}

/*----- Donation Amount -----*/
.cause-info .amount-input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 30rem;
}

.cause-info .amount-input-box input{
    width: 100%;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.cause-info .amount-input-box input:focus{
    border-color: var(--main-color);
}

.cause-info .amount-input-box label{
    color: var(--white);
    background-color: var(--secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
}

.cause-info .amount-choices{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-top: 1rem;
}

.cause-info .amount-choices .amount-item{
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.05);
    border: var(--border);
    color: var(--black);
    font-size: 1.6rem;
    cursor: pointer;
}

.cause-info .amount-choices .amount-item:hover{
    color: var(--white);
    background-color: var(--secondary-color);
}


/*----- Payment Options -----*/
.cause-info .payment-options{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4rem;
}

.cause-info .payment-item{
    cursor: pointer;
    font-size: 1.8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cause-info .payment-item  input{
    position: absolute;
    opacity: 0;
}

.cause-info .payment-item  input + .payment-label:before {
    content: '';
    background: #f4f4f4;
    border-radius: 100%;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    position: relative;
    margin-right: 1rem; 
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;

}
.cause-info .payment-item  input:checked + .payment-label:before {
    background-color: var(--secondary-color);
    -webkit-box-shadow: inset 0 0 0 4px #f4f4f4;
            box-shadow: inset 0 0 0 4px #f4f4f4;
}

.cause-info .payment-item  input:focus + .payment-label:before {
    outline: none;
    border-color: var(--secondary-color);
}

/*----- Personal Information -----*/
.cause-info .personal-info textarea{
    resize: none;
    height: 20rem;
}