.price-filter{
    margin-top: 4rem;
}

.price-filter .filter-btn{
    display: flex;
    justify-content: flex-end;
}

.price-filter .btn{
    border-radius: 0;
}

.input-range{
    margin-bottom: 3rem;
}

.input-range__track{
    background: #ddd !important;
    height: 0.5rem !important;
}

.input-range__track--active {
    background: var(--secondary-color) !important;
}

.input-range__slider {
    background: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color) !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    margin-top: -1rem !important;
}

.input-range__label {
    color: #666 !important;
    font-size: 1.5rem !important; 
}

.input-range__label--min, .input-range__label--max {
    bottom: -2.2rem !important;
}

.input-range__label--value {
    top: -3rem !important;
}